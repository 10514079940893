var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar-nav"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),(_vm.configuration)?_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid
					}
				}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" New Localization Project ")])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v("New Localization Project")]),_c('div',{staticClass:"my-5"},[_c('localization-project-form',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('button',{staticClass:"btn btn-success d-inline",on:{"click":_vm.save}},[_vm._v("SAVE")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }