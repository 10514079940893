<template>
	<div>
		<nav class="navbar-nav">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item" v-if="project">
						<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
					</li>

					<li class="breadcrumb-item active" aria-current="page" v-if="configuration">
						<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
						</router-link>
					</li>

					<li class="breadcrumb-item active">
						New Localization Project
					</li>

				</ol>
			</nav>

			<div class="container">
				<div class="row d-flex justify-content-center">
					<div class="col-6">
						<h3>New Localization Project</h3>
						<div class="my-5">
							<localization-project-form v-model="model"/>
							<button class="btn btn-success d-inline" @click="save">SAVE</button>
						</div>
					</div>
				</div>
			</div>

		</nav>
	</div>
</template>

<script>
import api from '@/api'
import LocalizationProjectForm from "@/components/localizations/LocalizationProjectForm";

export default {
	name: "new-project",
	components: {LocalizationProjectForm},
	data: () => ({
		project: null,
		configuration: null,
		model: {
			Name: '',
			ProjectConfigfk: null
		}
	}),
	methods: {
		async save() {
			let project = await api.Localizations.LocalizationProjects.saveNew(this.model)
			this.$router.push({
				name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
				params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
					project_id: project.Id
				}
			})
		}
	},
	async mounted() {
		this.loading = true
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		this.model.ProjectConfigfk = this.configuration.Id
		this.loading = false
	}
}
</script>

