<template>
	<vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
</template>
<script>
import api from '@/api'

export default {
	name: 'localization-project-form',
	props: {
		value: {},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	data: () => ({
		formOptions: {
			validateAfterLoad: true,
			validateAfterChanged: true,
			validateAsync: true
		},
		gamePaths: []
	}),
	async mounted() {
		this.gamePaths = await api.Games.getGamePaths()
	},
	computed: {
		schema() {
			return {
				fields: [
					{
						type: 'input',
						inputType: 'text',
						label: 'Name',
						model: 'Name',
						featured: true,
						required: true,
						disabled: this.disabled
					},
					{
						type: 'select',
						label: 'Assets Folder',
						model: 'AssetsFolder',
						values: this.gamePaths
					}
				]
			}
		},
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit('input', _val)
			}
		}
	}
}
</script>
